<template>
    <div>
        
        <ActualizarEmpresas :tipoBd="'CONTA'" />
        <!--<CRow>
            <CCol>
                <CButton
                    class="btnPrincipalVapp mb-1"
                    style="width:auto"
                    variant="ghost"
                    size="sm"
                    color="success"
                    @click="actualizar"
                    :disabled="isActualizandoEmpresas">
                    <CIcon :content="$options.freeSet.cilLoopCircular" :class="classActualizar" /> {{textoBtnActualizarEmpresas}}
                </CButton>
                
                <CButton
                    class="btnPrincipalVapp mb-1"
                    style="width:auto"
                    size="sm"
                    color="danger"
                    @click="cancelarActualizarEmpresas"
                    v-show="isActualizandoEmpresas"
                    v-c-tooltip.hover="{content:'Cancelar Actualización', placement:'right'}">
                    <CIcon :content="$options.freeSet.cilXCircle" /> Detener Actualización
                </CButton>
            </CCol>
        </CRow>-->
                        
        <CRow>
            <CCol>
                <CCard class="tarjetaGesto">
                    <CCardHeader>
                        <h4>Estado Resultado</h4>
                    </CCardHeader>
                    
                    <CCardBody>
                        <CRow><!--switch select ulti periodo-->
                            <CCol md="4">
                                Seleccionar último período en proceso
                                <CSwitch
                                    width="150px"
                                    class="mx-1 align-middle mb-2 switchGesto"
                                    shape="pill"
                                    variant="outline"
                                    :checked="selectPeriodoActual"
                                    @update:checked="seleccionarPeriodoActual()"
                                />
                            </CCol>
                            <CCol md="4" v-show="estadoResultado.length>0">
                                Ocultar valores en Ceros
                                <CSwitch
                                    width="150px"
                                    class="mx-1 align-middle mb-2 switchGesto"
                                    shape="pill"
                                    variant="outline"
                                    v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                    :checked="ocultarCeros"
                                    @update:checked="ocultarCerosProcesar"
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol col="2">
                                <multiselect
                                    class="mb-2 d-inline-block multSelectGesto"
                                    :custom-label="customLabelEmp"
                                    v-model="empSelected"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    placeholder="Seleccionar Empresa" 
                                    label="nombreEmpresa" 
                                    track-by="codEmpresa" 
                                    :options="empresasInfo" 
                                    :multiple="false"
                                    @select="seleccionarEmpresa"></multiselect>
                            </CCol>
                            <CCol md="2">
                                
                                <multiselect 
                                    class="m-0 d-inline-block mb-2 multSelectGesto" 
                                    v-model="anioSelected"
                                    deselect-label="" 
                                    placeholder="Año" 
                                    select-label=""
                                    selectedLabel=""
                                    label="anio" 
                                    track-by="anio" 
                                    :options="aniosDesde" 
                                    :searchable="true"
                                    :disabled="empSelected == null"
                                    @select="seleccionarAnio"
                                >
                                </multiselect>
                               <!--<multiselect 
                                    class="m-0 d-inline-block mb-2" 
                                    v-model="anioSelected"
                                    deselect-label="" 
                                    placeholder="Año" 
                                    select-label=""
                                    selectedLabel=""
                                    :options="aniosProcesados" 
                                    :searchable="true"
                                    :disabled="empSelected == null"
                                    @select="seleccionarAnio"
                                >
                                </multiselect>-->
                            </CCol>
                            <CCol md="2">
                                
                                <multiselect class="m-0 d-inline-block mb-2 multSelectGesto" 
                                    v-model="mesDesdeSelected"
                                    deselect-label="" 
                                    placeholder="Desde" 
                                    select-label=""
                                    selectedLabel=""
                                    label="nombre" 
                                    track-by="valor" 
                                    :options="mesesDesde" 
                                    :searchable="true"
                                    :disabled="empSelected == null"
                                    @select="seleccionarMesDesde"
                                >
                                </multiselect>
                            </CCol>
                            <CCol md="2">
                                <multiselect class="m-0 d-inline-block mb-2 multSelectGesto" 
                                    v-model="mesHastaSelected"
                                    deselect-label="" 
                                    placeholder="Hasta" 
                                    select-label=""
                                    selectedLabel=""
                                    label="nombre" 
                                    track-by="valor" 
                                    :options="mesesHasta" 
                                    :searchable="true"
                                    :disabled="empSelected == null"
                                    @select="seleccionarMesHasta"
                                >
                                </multiselect>
                            </CCol>
                            
                            <CCol md="2" v-show="comparativo" >
                                <multiselect class="m-0 d-inline-block mb-2 multSelectGesto"
                                    v-show="comparativo==true"  
                                    v-model="anio2Selected"
                                    deselect-label="" 
                                    placeholder="Año 2" 
                                    select-label=""
                                    selectedLabel=""
                                    label="anio" 
                                    track-by="anio" 
                                    :options="aniosHasta" 
                                    :searchable="true"
                                    :disabled="empSelected == null"
                                    @select="seleccionarAnioHasta"
                                >
                                </multiselect>
                            </CCol>
                            <CCol md="2">
                                Comparativo <CSwitch
                                    width="150px"
                                    class="mt-1 mx-1 align-middle mb-2 switchGesto"
                                    shape="pill"
                                    variant="outline"
                                    v-bind="{labelOn: 'Si',labelOff: 'No'}"
                                    :checked="comparativo"
                                    @update:checked="comparativo = !comparativo; cambiarComparativo(comparativo)"
                                />
                            </CCol>
                        </CRow>
                        <CRow>                            
                            <CCol md="12">

                                <CButton
                                    size="sm"
                                    v-show="mesDesdeSelected != null && mesHastaSelected != null && anioSelected != null && empSelected != null && ((!comparativo && anio2Selected == null) || (comparativo && anio2Selected != null)) && estadoResultado.length >0"
                                    variant="ghost"
                                    class="btnPrincipalVapp mb-1 mr-1"
                                    @click="verModalDocumentos = true"
                                    :disabled="isLoadingPdf"
                                    v-c-tooltip.hover="{content: 'Generar Documento', placement: 'over'}">
                                        Generar Documento
                                </CButton>
                                <!--<CButton
                                    size="sm"
                                    v-show="mesDesdeSelected != null && mesHastaSelected != null && anioSelected != null && empSelected != null && true==((comparativo)?anio2Selected != null:true)"
                                    variant="ghost"
                                    class="btnPrincipalVapp mb-1 mr-1"
                                    @click="mostrarModal('csv')"
                                    :disabled="isLoadingCsv"
                                    v-c-tooltip.hover="{content: 'Generar CSV',placement: 'over'}">
                                        Generar CSV
                                </CButton>-->
                            </CCol>
                        </CRow>
                        <!--<CRow>
                            <CCol md="2">
                                <CButton
                                    size="sm"
                                    v-show="mesDesdeSelected != null && mesHastaSelected != null && anioSelected != null && empSelected != null && true==((comparativo)?anio2Selected != null:true)"
                                    variant="ghost"
                                    class="btnPrincipalVapp mb-1"
                                    @click="generarPdf"
                                    :disabled="isLoadingPdfEstadoResultados"
                                    >
                                        <CIcon v-if="isLoadingPdfEstadoResultados" :content="$options.freeSet.cilCloudDownload" class="imgr" />
                                        <CIcon v-else :content="$options.freeSet.cilCloudDownload" v-c-tooltip.hover="{content: 'Descargar PDF',placement: 'over'}" />
                                        PDF
                                </CButton>
                                <CButton
                                    size="sm"
                                    v-show="mesDesdeSelected != null && mesHastaSelected != null && anioSelected != null && empSelected != null && true==((comparativo)?anio2Selected != null:true)"
                                    variant="ghost"
                                    class="btnPrincipalVapp mb-1 ml-1"
                                    @click="generarCsv"
                                    :disabled="isLoadingCsv"
                                    >
                                        <CIcon v-if="isLoadingCsv" :content="$options.freeSet.cilCloudDownload" class="imgr"  />
                                        <CIcon v-else :content="$options.freeSet.cilCloudDownload" v-c-tooltip.hover="{content: 'Descargar CSV',placement: 'over'}" />
                                        CSV
                                </CButton>
                            </CCol>
                            
                        </CRow>-->
                        
                        <CRow>
                            <CCol col="12">
                                <a-table
                                    row-key="codigo"
                                    :columns="columnasEstadoResultado" 
                                    :data-source="estadoResultado"
                                    :pagination="{'pageSize': totalPagina}"
                                    :scroll="{ y: 400 }"
                                    :loading="isLoadingEstadoResultados"
                                    class="components-table-demo-nested mt-3"
                                    size="small" >
                                        <span slot="codigo" slot-scope="er">
                                            {{er.codigo}}
                                        </span>
                                        <span slot="descripcion" slot-scope="er">
                                            {{er.descripcion}}
                                        </span>
                                        <span slot="saldo" slot-scope="er">
                                            {{er.saldo | currency}}
                                        </span>
                                        <span slot="saldoAnio2" slot-scope="er">
                                            {{er.saldoAnio2 | currency}}
                                        </span>
                                        <span slot="analisisVert" slot-scope="er">
                                            {{er.analisisVert | currency(configPorcentaje)}}
                                        </span>
                                        <span slot="analisisHor" slot-scope="er">
                                            {{er.analisisHor | currency(configPorcentaje)}}
                                        </span>
                                </a-table>
                            </CCol>
                        </CRow>
                    </CCardBody>
                    <CCardFooter>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
        
        <Modal
            v-model="verModalDocumentos"
            :title="'Vista previa del documento'"
            :modal-style="{ 'min-width': '300px', 'max-width': '1140px' }"
            :enableClose="false"
            @close="cerrarModal">
            <CRow>
                <CCol md="12">
                    Generar PDF <CSwitch
                        width="150px"
                        class="mt-1 mx-1 align-middle mb-2"
                        shape="pill"
                        variant="outline"
                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                        :checked="selectPdf"
                        @update:checked="selectPdf = !selectPdf;cambiotipoDoc('pdf')"
                    />
                    Generar CSV <CSwitch
                        width="150px"
                        class="mt-1 mx-1 align-middle mb-2"
                        shape="pill"
                        variant="outline"
                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                        :checked="selectCsv"
                        @update:checked="selectCsv = !selectCsv;cambiotipoDoc('csv')"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol md="5" v-show="selectPdf">
                    Generar con firmas <CSwitch
                        width="150px"
                        class="mt-1 mx-1 align-middle mb-2 switchGesto"
                        shape="pill"
                        variant="outline"
                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                        :checked="generarConFirmas"
                        @update:checked="generarConFirmas = !generarConFirmas"
                    />
                    Generar con Art. 100 <CSwitch
                        width="150px"
                        class="mt-1 mx-1 align-middle mb-2 switchGesto"
                        shape="pill"
                        variant="outline"
                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                        :checked="generarConArt100"
                        @update:checked="generarConArt100 = !generarConArt100"
                    />
                </CCol>
                <CCol md="2">
                    
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="6" md="6" lg="6">
                    <CButton
                        size="sm"
                        v-show="selectPdf || selectCsv"
                        variant="ghost"
                        class="btnPrincipalVapp mb-1 mr-1"
                        @click="generarDocumento"
                        :disabled="isLoadingPdf">
                            <CIcon v-show="isLoadingPdf" :content="$options.freeSet.cilLoopCircular" class="imgr" />
                            Generar Documento
                    </CButton>
                    <CButton
                        v-show="documentoGenerado"
                        size="sm"
                        variant="ghost"
                        class="btnPrincipalVapp mb-1 mr-1"
                        @click="descargarDocumento"
                        :disabled="isLoadingDocumento"
                        v-c-tooltip.hover="{content: 'Descargar Documento generado',placement: 'rigth'}">
                            <CIcon size="sm" :content="$options.freeSet.cilCloudDownload" />
                    </CButton>
                    
                </CCol>
            </CRow>
            <CRow v-show="isLoadingDocumento">
                <CCol>
                    <center>
                        <!--<CIcon v-show="isLoadingDocumento" :content="$options.freeSet.cilLoopCircular" class="imgr" style="height: 50px; width: 50px;" />-->
                        <img src="/img/logo2.png" :height="50" class="imgr mt-3" /> Generando...
                    </center>
                </CCol>
            </CRow>
            
            <!--<CIcon v-show="isLoadingPdf || isLoadingCsv" :content="$options.freeSet.cilLoopCircular" class="imgr" style="height: 100px; width: 100px;" />-->
            <iframe
                v-show="!isLoadingDocumento && documentoGenerado"
                :src="docSeleccionado.linkView"
                width="100%"
                height="400"
                allow="autoplay"
                ></iframe>
            <CRow>
            <CCol sm="12" md="12" lg="12">
                <CButton
                md="3"
                class="btnPrincipalVapp m-3 float-right"
                style="margin: 20%"
                @click="cerrarModal"
                >Cerrar</CButton
                ></CCol
            ></CRow
            >
        </Modal>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    
    import cons from '@/const'
    import axios from "axios"

    //import Editor from "./Editor"
    //import EditorXls from "./EditorExcel"

    import Multiselect from 'vue-multiselect'

    import VueModal from '@kouts/vue-modal';
    import '@kouts/vue-modal/dist/vue-modal.css';
    
    import { freeSet } from '@coreui/icons'

    import moment from 'moment'
    import { format, formatDistance, formatRelative, subDays } from 'date-fns'
    import es from 'date-fns/locale/es'
    window.locale = 'es'
    
    import ActualizarEmpresas from "@/views/utils/ActualizarEmpresas";
    

    export default {
        name: 'EstadoResultados',
        freeSet,
        components: {
            Multiselect,
            'Modal': VueModal,
            ActualizarEmpresas,
            //Editor,
            //EditorXls,

            //DocumentEditor: [Toolbar]            
        },
        data () {
            return {
                
                urlBase: cons.port+"://" + cons.ipServer,
                
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                tipoUserAdmin: JSON.parse(this.$cookie.get('userLogginToken')).tipoUsuario,
                idCliente: JSON.parse(this.$cookie.get('userLogginToken')).idCliente,
                
                regMail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

                configPorcentaje: {
                    symbol: '%',
                    thousandsSeparator: '.',
                    fractionCount: 2,
                    fractionSeparator: ',',
                    symbolPosition: 'back',
                    symbolSpacing: false,
                    avoidEmptyDecimals: undefined,
                },

                selectPeriodoActual: false,

                rqstActualizarEmpresas: null,
                isActualizandoEmpresas: false,
                classActualizar: "",
                isActualizandoEmpresas: false,
                textoBtnActualizarEmpresas: "Actualizar Empresas",
                mensajesActualizacion: [],
                mostrarMensajes: false,

                ocultarCeros: true,
               

                //meses: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                aniosDesde: [],
                aniosHasta: [],
                mesesDesde: [
                    {"nombre": "Enero", "valor": 1 }, 
                    {"nombre": "Febrero", "valor": 2}, 
                    {"nombre": "Marzo", "valor": 3}, 
                    {"nombre": "Abril", "valor": 4}, 
                    {"nombre": "Mayo", "valor": 5}, 
                    {"nombre": "Junio", "valor": 6}, 
                    {"nombre": "Julio", "valor": 7}, 
                    {"nombre": "Agosto", "valor": 8}, 
                    {"nombre": "Septiembre", "valor": 9}, 
                    {"nombre": "Octubre", "valor": 10}, 
                    {"nombre": "Noviembre", "valor": 11}, 
                    {"nombre": "Diciembre", "valor": 12}
                ],
                mesesHasta: [
                    {"nombre": "Enero", "valor": 1, $isDisabled: false}, 
                    {"nombre": "Febrero", "valor": 2, $isDisabled: false}, 
                    {"nombre": "Marzo", "valor": 3, $isDisabled: false}, 
                    {"nombre": "Abril", "valor": 4, $isDisabled: false}, 
                    {"nombre": "Mayo", "valor": 5, $isDisabled: false}, 
                    {"nombre": "Junio", "valor": 6, $isDisabled: false}, 
                    {"nombre": "Julio", "valor": 7, $isDisabled: false}, 
                    {"nombre": "Agosto", "valor": 8, $isDisabled: false}, 
                    {"nombre": "Septiembre", "valor": 9, $isDisabled: false}, 
                    {"nombre": "Octubre", "valor": 10, $isDisabled: false}, 
                    {"nombre": "Noviembre", "valor": 11, $isDisabled: false}, 
                    {"nombre": "Diciembre", "valor": 12, $isDisabled: false}
                ],

                mesDesdeSelected: null,
                mesHastaSelected: null,
                anioSelected: null,
                anio2Selected: null,

                empresasInfo: [],

                empSelected: null,
                totalPagina: 20,

                comparativo: false,
                
                isLoadingEmpresasInfo: false,
                isLoadingEstadoResultados: false,

                columnasEstadoResultado: [
                    { title: 'Código', scopedSlots: { customRender: 'codigo' }, width: '33%', align: 'left', ellipsis: true},
                    { title: 'Descripción', scopedSlots:{ customRender: 'descripcion' }, width: '33%', align: 'right', ellipsis: true},
                    { title: 'Saldo', scopedSlots: { customRender: 'saldo' }, width: '33%', align: 'right', ellipsis: false}
                ],
                estadoResultado: [],


                /* Variables Modal*/

                docSeleccionado: {
                    "link": "",
                    "nombre": "",
                    "linkView": ""
                },

                verModalDocumentos: false,

                generarConFirmas: false,
                generarConArt100: false,
                selectPdf: false,
                selectCsv: false,

                isLoadingDocumento: false,
                isLoadingPdf: false,
                isLoadingCsv: false,
                documentoGenerado: false,


               

            }
        },
        created: function(){
            
        },
        beforeMount() {
            let cookieUsername = this.$cookie.get('userLogginToken')
            if(cookieUsername === null || cookieUsername === ""){
                this.$router.push("/pages/login");
            }


        },
        mounted(){
            this.obtenerSoloInfoEmpresas()
            
        },
        methods: {
             
            ocultarCerosProcesar: function(){
                this.ocultarCeros = !this.ocultarCeros

                if(this.comparativo){
                    if(this.anio2Selected != null)
                        this.getER(this.anio2Selected)
                }
                else{
                    this.getER(this.anioSelected)
                }

                
            },
            obtenerPeriodoEmpresa: function(){

                var url = this.urlBase + "/get_periodo_empresa_estado_resultado";
                this.aniosDesde = []
                this.aniosHasta = []
                this.mesesDesde = []
                this.mesesHasta = []

                
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "idCliente": this.idCliente,
                        "idEmpresa": this.empSelected.idEmpresa
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.aniosDesde = result.data.aniosDesde
                    this.aniosHasta = result.data.aniosHasta


                    if(this.selectPeriodoActual){
                        this.seleccionarPeriodoActualCargaEmpresa()
                        
                        this.selectPeriodoActual = !this.selectPeriodoActual
                    } else{
                        
                        if(this.anioSelected != null){
                            this.anioSelected = this.aniosDesde.find(anio => anio.anio === this.anioSelected.anio)
                            this.mesesDesde = this.anioSelected.mesesDesde
                            this.mesesHasta = this.anioSelected.mesesHasta
                        }
                        if(this.anio2Selected != null){
                            this.anio2Selected = this.aniosHasta.find(anio => anio.anio === this.anio2Selected.anio)
                        }
                        if(this.mesDesdeSelected != null){
                            this.mesDesdeSelected = this.mesesDesde.find(mes => mes.valor === this.mesDesdeSelected.valor);
                        }
                        if(this.mesHastaSelected != null){
                            this.mesHastaSelected = this.mesesHasta.find(mes => mes.valor === this.mesHastaSelected.valor);
                        }

                    }

                }, error => {
                    //let mensaje = `Problema al obtener empresas , ${error}`
                    //this.textoSinEmpresas = mensaje
                })
                .finally(() => {
                });
            },
            obtenerSoloInfoEmpresas: function(){
                this.isLoadingEmpresasInfo = true
                var url = this.urlBase + "/get_empresas_info/CONTA";
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.empresasInfo = result.data;

                }, error => {
                    //let mensaje = `Problema al obtener empresas , ${error}`
                    //this.textoSinEmpresas = mensaje
                })
                .finally(() => {
                    this.isLoadingEmpresasInfo = false
                });
            },
            cambiarComparativo: function(comparativo){
                if(!comparativo){
                    
                    //this.columnasEstadoResultado.push({ title: 'Saldo Año 2', scopedSlots: { customRender: 'saldoAnio2' }, width: '15%', align: 'center', ellipsis: true})
                    //   this.columnasEstadoResultado.push({ title: 'Análisis Vertical', scopedSlots: { customRender: 'analisisVert' }, width: '15%', align: 'center', ellipsis: true})
                    //  this.columnasEstadoResultado.push({ title: 'Análisis Horizontal', scopedSlots: { customRender: 'analisisHor' }, width: '15%', align: 'center', ellipsis: true})

                        
                    var encontrado = this.columnasEstadoResultado.indexOf(this.columnasEstadoResultado.find(columna => columna.scopedSlots.customRender == 'saldoAnio2'))
                    if ( encontrado !== -1 ) {
                        this.columnasEstadoResultado.splice(encontrado, 1)
                    }
                    var encontrado = this.columnasEstadoResultado.indexOf(this.columnasEstadoResultado.find(columna => columna.scopedSlots.customRender == 'analisisVert'))
                    if ( encontrado !== -1 ) {
                        this.columnasEstadoResultado.splice(encontrado, 1)
                    }
                    var encontrado = this.columnasEstadoResultado.indexOf(this.columnasEstadoResultado.find(columna => columna.scopedSlots.customRender == 'analisisHor'))
                    if ( encontrado !== -1 ) {
                        this.columnasEstadoResultado.splice(encontrado, 1)
                    }
                }
                if(this.empSelected != null && this.mesDesdeSelected != null && this.mesHastaSelected != null){
                    if(this.comparativo){
                        if(this.anio2Selected != null)
                            this.getER(this.anio2Selected)
                    }
                    else{
                        this.getER(this.anioSelected)
                    }

                }
            },
            
            getER: function(anio){
                this.isLoadingEstadoResultados = true
                if(this.comparativo){
                    this.anio2Selected = anio
                } else{
                    this.anioSelected = anio
                }
                
                this.estadoResultado = []

                var url = this.urlBase + "/get_estado_resultado_empresa";
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.empSelected.idEmpresa,
                            "mesDesde": this.mesDesdeSelected.nombre,
                            "mesHasta": this.mesHastaSelected.nombre,
                            "anio": this.anioSelected.anio,
                            "comparativo": this.comparativo,
                            "anio2": (this.anio2Selected!=null)?this.anio2Selected.anio:null,
                            "ocultarCeros": this.ocultarCeros
                        },
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    if(this.comparativo){
                    
                            
                        this.columnasEstadoResultado.push({ title: 'Saldo Año 2', scopedSlots: { customRender: 'saldoAnio2' }, width: '15%', align: 'center', ellipsis: true})
                        this.columnasEstadoResultado.push({ title: 'Análisis Vertical', scopedSlots: { customRender: 'analisisVert' }, width: '15%', align: 'center', ellipsis: true})
                        this.columnasEstadoResultado.push({ title: 'Análisis Horizontal', scopedSlots: { customRender: 'analisisHor' }, width: '15%', align: 'center', ellipsis: true})
                    }
                    
                    this.totalPagina = result.data.length
                    this.estadoResultado = result.data;
                    this.isLoadingEstadoResultados = false
                    
                    this.selectPeriodoActual = false

                }, error => {
                    this.isLoadingEstadoResultados = false
                    this.selectPeriodoActual = false
                    this.$notification.error(
                        error.response.data.mensajeGeneral,
                        { timer: 10, position: "bottomRight" }
                    );
                });

            },
            seleccionarEmpresa: function(emp){
                this.empSelected = emp
                this.obtenerPeriodoEmpresa()
                if(this.mesDesdeSelected != null && this.mesHastaSelected != null && this.anioSelected != null){
                    if(this.comparativo){
                        if(this.anio2Selected != null)
                            this.getER(this.anio2Selected)
                    } else{
                        this.getER(this.anioSelected)
                    }
                }

            },
            seleccionarMesDesde: function(mesDesde){
                this.validarMesHasta(mesDesde)
                this.mesDesdeSelected = mesDesde
                if(this.mesHastaSelected != null){
                    if(this.mesHastaSelected.valor < mesDesde.valor){
                        this.mesHastaSelected = null
                    } else{
                        if(this.mesDesdeSelected != null && this.mesHastaSelected != null && this.anioSelected != null){
                            if(this.comparativo){
                                if(this.anio2Selected != null)
                                    this.getER(this.anio2Selected)
                            } else{
                                this.getER(this.anioSelected)
                            }
                        }
                    }
                }
            },
            seleccionarMesHasta: function(mesHasta){
                this.mesHastaSelected = mesHasta
                if(this.empSelected != null && this.mesDesdeSelected != null && this.anioSelected != null){
                    if(this.comparativo){
                        if(this.anio2Selected != null)
                            this.getER(this.anio2Selected)
                    } else{
                        this.getER(this.anioSelected)
                    }
                }
            },

            validarMesHasta: function(opcion){
                this.mesesHasta.forEach(mes =>{
                    if(mes.valor < opcion.valor){
                        mes.$isDisabled = true
                    }
                    else{
                        mes.$isDisabled = false
                    }
                })
            },
            seleccionarAnio: function(anio){
                this.validarAnioHasta(anio.anio)
                this.anioSelected = anio

                this.mesesDesde = anio.mesesDesde
                this.mesesHasta = anio.mesesHasta

                if(this.empSelected != null && this.mesDesdeSelected != null && this.mesHastaSelected != null){
                    if(this.comparativo){
                        if(this.anio2Selected != null)
                            this.getER(this.anio2Selected)
                    }
                    else{
                        this.getER(this.anioSelected)
                    }

                }
            },
            seleccionarAnioHasta: function(anio){
                this.anio2Selected = anio
                if(this.empSelected != null && this.mesDesdeSelected != null && this.mesHastaSelected != null && this.anioSelected != null){
                    if(this.comparativo){
                        if(this.anio2Selected != null)
                            this.getER(this.anio2Selected)
                    }
                    else{
                        this.getER(this.anioSelected)
                    }

                }
            },
            validarAnioHasta: function(opcion){
                this.aniosHasta.forEach(anio => {
                    if(anio.valor < opcion){
                        anio.$isDisabled = true
                    } else{
                        anio.$isDisabled = false
                    }
                })
            },
            

            generarCsv: function(){
                this.isLoadingDocumento = true
                this.documentoGenerado = false
                
                this.isLoadingCsv = true

                var url = this.urlBase + "/generar_csv_estado_resultado";

                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.empSelected.idEmpresa,
                            "mesDesde": this.mesDesdeSelected.nombre,
                            "mesHasta": this.mesHastaSelected.nombre,
                            "anio": this.anioSelected.anio,
                            "comparativo": this.comparativo,
                            "anio2": (this.anio2Selected!=null)?this.anio2Selected.anio:null,
                            "agregarArt100": this.generarConArt100,
                            "agregarFirmas": this.generarConFirmas,
                            "ocultarCeros": this.ocultarCeros
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.isLoadingDocumento = false
                    
                    this.isLoadingCsv = false

                    var link = result.data.archivoGenerado.link
                    
                    /*var fileLink = document.createElement("a");
                    fileLink.href = link;
                    fileLink.setAttribute("download", result.data.archivoGenerado.nombre);
                    document.body.appendChild(fileLink);
                    fileLink.click();*/
                    this.docSeleccionado = {
                        "link": result.data.archivoGenerado.link,
                        "nombre": result.data.archivoGenerado.nombre,
                        "linkView": result.data.archivoGenerado.linkView.replace('view', 'preview'),
                    }
                    this.pdfGenerado = true
                    this.documentoGenerado = true

                    this.$notification.success(
                        result.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                    );

                }, error => {
                    
                    this.documentoGenerado = false
                    this.isLoadingDocumento = false
                    
                    this.isLoadingCsv = false
                    this.$notification.error(
                        error.response.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                    );
                });
            },

            generarPdf: function(){
                this.isLoadingDocumento = true
                this.documentoGenerado = false

                this.isLoadingPdf = true

                var url = this.urlBase + "/generar_pdf_estado_resultado";

                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.empSelected.idEmpresa,
                            "mesDesde": this.mesDesdeSelected.nombre,
                            "mesHasta": this.mesHastaSelected.nombre,
                            "anio": this.anioSelected.anio,
                            "comparativo": this.comparativo,
                            "anio2": (this.anio2Selected!=null)?this.anio2Selected.anio:null,
                            "agregarArt100": this.generarConArt100,
                            "agregarFirmas": this.generarConFirmas,
                            "ocultarCeros": this.ocultarCeros
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.isLoadingDocumento = false
                    
                    this.isLoadingPdf = false

                     /*var link = result.data.archivoGenerado.link
                    
                   var fileLink = document.createElement("a");
                    fileLink.href = link;
                    fileLink.setAttribute("download", result.data.archivoGenerado.nombre);
                    document.body.appendChild(fileLink);
                    fileLink.click();*/
                    
                    this.docSeleccionado = {
                        "link": result.data.archivoGenerado.link,
                        "nombre": result.data.archivoGenerado.nombre,
                        "linkView": result.data.archivoGenerado.linkView.replace('view', 'preview'),
                    }
                    this.pdfGenerado = true
                    this.documentoGenerado = true

                    this.$notification.success(
                        result.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                    );

                }, error => {
                    this.documentoGenerado = false
                    this.isLoadingDocumento = false

                    this.isLoadingPdf = false
                    this.$notification.error(
                        error.response.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                    );
                });
            },

            seleccionarPeriodoActualCargaEmpresa: function(){
                this.mesDesdeSelected = null;
                this.mesHastaSelected = null;
                this.anioSelected = null;
                this.anio2Selected = null;
                
                
                const fechaActual = new Date();
                // Obtener el número del mes actual (0-11)
                const mesActualNumero = fechaActual.getMonth();
                const anioActual = fechaActual.getFullYear();
                let anioAc = this.aniosDesde.find(anio => anio.anio === anioActual)
                if(anioAc == null){
                    anioAc = this.aniosDesde[0]
                }
                let anioComparativo = (this.comparativo) ? this.aniosHasta.find(anio => anio.anio === anioActual-1) : null
                if(anioComparativo == null){
                    anioComparativo = (this.aniosHasta.length >1) ? this.aniosHasta[1] : this.aniosHasta[0]
                }
                this.seleccionarAnio(anioAc)
                if(this.comparativo){
                    this.seleccionarAnioHasta(anioComparativo)
                }

                let mesActual = this.mesesDesde.find(mes => mes.valor === mesActualNumero);
                let mesActual2 = this.mesesHasta.find(mes => mes.valor === mesActualNumero);

                if(mesActual == null){
                    mesActual = this.mesesDesde[this.mesesDesde.length-1]
                }

                if(mesActual2 == null){
                    mesActual2 = (this.mesesHasta.length>2) ? this.mesesHasta[this.mesesHasta.length-2] : this.mesesHasta[this.mesesHasta.length-1]
                }
                
                
                this.seleccionarMesDesde(mesActual)
                this.seleccionarMesHasta(mesActual2)
            },
            seleccionarPeriodoActual: function(){
                this.selectPeriodoActual = !this.selectPeriodoActual
                this.seleccionarEmpresa(this.empresasInfo[0])

                
                
                /*empSelected
                mesDesdeSelected
                mesHastaSelected
                anioSelected
                anio2Selected*/
            },

            customLabelEmp (empresa) {
                return `(${empresa.codEmpresa}) ${empresa.nombreEmpresa}`;
            },

            /* Metodos Modal */
            cerrarModal: function () {
                this.verDocumento = false;
                this.verModalDocumentos = false;
            },
            
            cambiotipoDoc: function(tipoDoc){
                if(tipoDoc === 'pdf'){
                    if(this.selectPdf)
                        this.selectCsv = false;
                }else if(tipoDoc === 'csv'){
                    if(this.selectCsv)
                        this.selectPdf = false;
                }
            },
            
            generarDocumento: function(){
                this.docSeleccionado = {
                    "link": "",
                    "nombre": "",
                    "linkView": ""
                }
                if(this.selectPdf){
                    this.generarPdf()
                }
                else if(this.selectCsv){
                    this.generarCsv()
                }
            },

            descargarDocumento: function(){
                /*var doc = null
                if(tipo === 'pdf'){
                    doc = this.docPdf
                }
                else if(tipo === 'csv'){
                    doc = this.docCsv
                }*/
                var fileLink = document.createElement("a");
                fileLink.href = this.docSeleccionado.link;
                fileLink.setAttribute("download", this.docSeleccionado.nombre);
                document.body.appendChild(fileLink);
                fileLink.click();
            },

        }
        
    }
</script>

<style>
    .imgr{
        -webkit-animation: 3s rotate linear infinite;
        animation: 3s rotate  linear infinite;
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    .search-box2{
        position: absolute;
        left: 60px;
        transform: translate(0, -50%);
        background: #2c3441;
        height: 40px;
        border-radius: 40px;
    }
    .search-box2:hover > input{
        width: 200px;
        padding: 0 10px;
    }
    .icon2{
        color: #21dfcd;
        float: right;
        width: 40px;
        font-size: 1.3rem;
        height: 40px;
        border-radius: 50%;
        background: #2f3640;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.4s;
        cursor: pointer;
        text-decoration: none;
    }
    .icon2:hover{
        transform: rotate(360deg) scale(0.8);
    }
    input{
        width: 0;
        border: none;
        outline: none;
        padding: 0;
        background: none;
        font-size: 1.1rem;
        transition: 0.5s ease;
        line-height: 40px;
        color: #fff;
    }
    

    .switchGesto .c-switch-input:checked + .c-switch-slider {
        border-color: #29235C;
    }
    .switchGesto .c-switch-input:checked + .c-switch-slider::before {
        background-color: #29235C;
        border-color: #29235C;
    }
    .switchGesto .c-switch-input:checked + .c-switch-slider::after {
        color: #29235C;
    }
    .switchGesto .c-switch-slider {
        border-color: #29235C;
    }
    .switchGesto .c-switch-slider::before {
        background-color: #29235C;
        border-color: #29235C;
    }
    .switchGesto .c-switch-slider::after {
        color: #461A3B;

    }

</style>